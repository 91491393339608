import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
class Wholesale{

    /**
     * 获取批发订单信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} cb 
     */
    getWholesaleOrder(page,limit,extra,cb){
        let param = { page,limit }
        if( extra.key ) param.key = extra.key
        common.getDataList("getWholesaleList",param).then(res=>cb(res))
    }

    /**
     * 获取订单详情
     * @param {number} id 
     * @param {function} cb 
     */
    getWholesaleOrderDetail(id,cb){
        $post("getWholesaleDetail",{id}).then(res=>cb(res.data))
    }
}

const wholesaleModel = new Wholesale()
export default wholesaleModel