<template>
	<div id="wholesaleOrderList">
		<div class="el-content mb12">
			<a-input-search
                v-model:value="search.key"
                placeholder="请输入订单编号、收货人、联系电话"
                style="width:400px;"
                enter-button
                @search="getOrderList(1)"
            />
		</div>
		<div class="el-content">
            <a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
                {title:'订单号',dataIndex:'order_number'},
                {title:'下单时间',dataIndex:'create_time'},
                {title:'分销商名称',dataIndex:'name'},
                {title:'金额',dataIndex:'total_price'},
                {title:'是否支付',dataIndex:'is_pay'},
                {title:'支付方式',dataIndex:'paty_method'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" >
                <template #is_pay="{record}">{{isPay(record.is_pay)}}</template>
                <template #paty_method="{record}">{{$util.payMethod(record.pay_method)}}</template>
                <template #action="{record}">
                    <router-link :to="{path:'/wholesale/orderDetail',query:{id:record.order_id}}">
                        <kd-button 
                            type="primary" 
                            title="编辑" 
                            icon="ri-more-fill" 
                            v-has="{action:'/wholesale/orderDetail'}" >
                        </kd-button>
                    </router-link>
                </template>
            </a-table>
		</div>
	</div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue';
import wholesaleModel from '@/api/addons/wholesale'
export default{
    setup(){
        const state = reactive({
            search:{
                key:'',
                time:[],
            },
            info:{
                list:[],
                page:1,
                limit:10,
                count:0
            }
        })
        getWholesaleOrderList(1,state.info.limit)

        const isPay = computed(()=>{
            return function(state){
                return state ==1 ?'已支付':'未支付'
            }
        })

        function getWholesaleOrderList(page,limit){
            wholesaleModel.getWholesaleOrder(page,limit,state.search,res=>state.info = {limit,...res})
        }

        return{
            ...toRefs(state),
            isPay,
            getWholesaleOrderList
        }
    }
}
</script>

<style lang="scss">
// 顶部搜索
.kd-sale-order-top{
    span{
        color: #868686;
        font-size: 14px;
        margin-left: 13px;
        margin: 0 20px 0 13px;
    }
    
}
</style>
